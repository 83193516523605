import { defineStore } from 'pinia'
// import { setToken } from '../utils/auth'

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            uuid: '',
            userName: '',
            email: '',
            email_verified_at: '',
            currency: '',
            currency_symbol: '',
            whatsapp_msisdn: '',
            whatsapp_msisdn_verified_at: '',
            notification: {
                days: [],
                time: 'None',
            },
        }
    },

    actions: {
        async setCurrentUser(user: any) {
            this.uuid = user.uuid
            this.userName = user.username
            this.email = user.email
            this.email_verified_at = user.email_verified_at
            this.currency = user.currency
            this.currency_symbol = user.currency_symbol
            this.whatsapp_msisdn = user.whatsapp_msisdn
            this.whatsapp_msisdn_verified_at = user.whatsapp_msisdn_verified_at
            this.notification = user.notification
        },
        logoutUser() {
            this.uuid = ''
            this.userName = ''
            this.email = ''
            this.email_verified_at = ''
            this.currency = ''
            this.currency_symbol = ''
            this.whatsapp_msisdn = ''
            this.whatsapp_msisdn_verified_at = ''
        },
    },
})
