import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
    state: () => {
        return {
            isSidebarMinimized: true,
        }
    },

    actions: {
        toggleSidebar() {
            this.isSidebarMinimized = !this.isSidebarMinimized
        },
    },
})
