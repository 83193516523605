{
    "auth": {
        "agree": "Acepto",
        "createAccount": "Crear cuenta",
        "createNewAccount": "Crear cuenta nueva",
        "email": "Email",
        "login": "Iniciar sesión",
        "password": "Contraseña",
        "recover_password": "Recuperar contraseña",
        "sign_up": "Registrar",
        "keep_logged_in": "Mantenerme conectado",
        "termsOfUse": "Términos de uso",
        "reset_password": "Restablecer contraseña"
    },
    "404": {
        "title": "Esta página se ha ido a pescar",
        "text": "Si crees que esto no es correcto, por favor envíanos un mensaje a ",
        "back_button": "Volver al tablero"
    },
    "typography": {
        "primary": "Estilos de texto primarios",
        "secondary": "Estilos de texto secundarios"
    },
    "dashboard": {
        "versions": "Versiones",
        "setupRemoteConnections": "Configurar conexiones remotas",
        "currentVisitors": "Visitantes actuales",
        "charts": {
            "trendyTrends": "Tendencias modernas",
            "showInMoreDetail": "Mostrar en más detalle",
            "showInLessDetail": "Mostrar en menos detalle",
            "loadingSpeed": "Velocidad de carga",
            "topContributors": "Principales contribuyentes",
            "showNextFive": "Mostrar los siguientes cinco",
            "commits": "Commit"
        },
        "info": {
            "componentRichTheme": "Tema rico en componentes",
            "completedPullRequests": "Solicitudes de pull completadas",
            "users": "Usuarios",
            "points": "Puntos",
            "units": "Unidades",
            "exploreGallery": "Explorar galería",
            "viewLibrary": "Ver biblioteca",
            "commits": "Commits",
            "components": "Componentes",
            "teamMembers": "Miembros del equipo"
        },
        "tabs": {
            "overview": {
                "title": "Visión general",
                "built": "Construido con el framework Vue.js",
                "free": "Totalmente gratuito para todos",
                "fresh": "Diseño fresco y nítido",
                "mobile": "Responsivo y optimizado para móviles",
                "components": "Toneladas de componentes útiles",
                "nojQuery": "Totalmente libre de jQuery"
            },
            "billingAddress": {
                "title": "Dirección de facturación",
                "personalInfo": "Información personal",
                "firstName": "Nombre y apellido",
                "email": "Email",
                "address": "Dirección",
                "companyInfo": "Información de la empresa",
                "city": "Ciudad",
                "country": "País",
                "infiniteConnections": "Conexiones infinitas",
                "addConnection": "Añadir conexión"
            },
            "bankDetails": {
                "title": "Detalles del banco",
                "detailsFields": "Campos de detalles",
                "bankName": "Nombre del banco",
                "accountName": "Nombre de la cuenta",
                "sortCode": "Código de ordenación",
                "accountNumber": "Número de cuenta",
                "notes": "Notas",
                "sendDetails": "Enviar detalles"
            }
        },
        "navigationLayout": "Diseño de navegación",
        "topBarButton": "Barra superior",
        "sideBarButton": "Barra lateral"
    },
    "language": {
        "brazilian_portuguese": "Portugués brasileño",
        "english": "Inglés",
        "spanish": "Español",
        "simplified_chinese": "Chino simplificado",
        "persian": "Persa"
    },
    "menu": {
        "auth": "Autenticación",
        "buttons": "Botones",
        "timelines": "Líneas de tiempo",
        "dashboard": "Tablero",
        "billing": "Facturación",
        "login": "Iniciar sesión",
        "preferences": "Preferencias",
        "payments": "Pagos",
        "pricing-plans": "Planes de precios",
        "login-singup": "Iniciar sesión/Registrarse",
        "404": "Páginas 404",
        "faq": "FAQ"
    },
    "messages": {
        "all": "Ver todos los mensajes",
        "new": "Nuevos mensajes de {name}",
        "mark_as_read": "Marcar como leído"
    },
    "navbar": {
        "messageUs": "Preguntas sobre desarrollo web:",
        "repository": "Repositorio GitHub"
    },
    "notifications": {
        "all": "Ver todas las notificaciones",
        "mark_as_read": "Marcar como leída",
        "sentMessage": "{name} te envió un mensaje",
        "uploadedZip": "{name} subió un archivo Zip con {type}",
        "startedTopic": "{name} inició un nuevo tema"
    },
    "user": {
        "language": "Cambiar Idioma",
        "logout": "Cerrar sesión",
        "profile": "Mi Perfil",
        "settings": "Configuración",
        "billing": "Facturación",
        "faq": "Preguntas Frecuentes",
        "helpAndSupport": "Ayuda & Soporte",
        "projects": "Proyectos",
        "account": "Cuenta",
        "explore": "Explorar"
    },
    "helpAndSupport": "Ayuda y Soporte",
    "aboutVuesticAdmin": "Acerca de Vuestic Admin",
    "search": {
        "placeholder": "Buscar..."
    }
}
