import axios from 'axios'
import { useToast } from 'vuestic-ui'
// import { getToken } from '../utils/auth'
const { init } = useToast()

const request = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API,
    timeout: 5000,
    withCredentials: true,
    withXSRFToken: true,
})

request.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = 'application/json'

        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

request.interceptors.response.use(
    (response: any) => response.data,
    (error: any) => {
        const response = error.response

        if ([422, 400].includes(response.status)) {
            init({
                title: 'Error Occured',
                message: response.data.message,
                color: 'danger',
                iconClass: 'mso-account_circle',
            })
        }

        if (response.status === 500) {
            init({
                title: 'Error Occured',
                message: response.data.message || 'Unable to perform action',
                color: 'danger',
            })
        }

        if (response.status === 401) {
            init({
                title: 'UNAUTHORIZED',
                message: response.data.message,
                color: 'danger',
            })
        }

        return Promise.reject(error)
    },
)

export default request
