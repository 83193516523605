import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    NavigationGuardNext,
    RouteLocationNormalized,
} from 'vue-router'
import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import GuestLayout from '../layouts/GuestLayout.vue'
import { getToken, removeToken } from '../utils/auth'
import { useUserStore } from '../stores/user-store'
import { getCurrentUser } from '../api/user'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: GuestLayout,
        meta: { requiresAuth: false },
        children: [
            {
                name: 'landing',
                path: '/',
                redirect: { name: 'login' },
            },
            {
                name: 'privacy',
                path: '/privacy',
                component: () => import('../pages/public/Privacy.vue'),
            },
        ],
    },
    {
        path: '/expense',
        component: AppLayout,
        meta: { requiresAuth: true },
        children: [
            {
                name: 'expense',
                path: '',
                component: () => import('../pages/expense/index.vue'),
            },
        ],
    },
    {
        path: '/item',
        component: AppLayout,
        meta: { requiresAuth: true },
        children: [
            {
                name: 'item',
                path: '',
                component: () => import('../pages/item/index.vue'),
            },
        ],
    },
    {
        path: '/auth',
        component: AuthLayout,
        meta: { requiresAuth: false },
        children: [
            {
                name: 'logout',
                path: 'logout',
                component: () => import('../pages/auth/Logout.vue'),
            },
            {
                name: 'login',
                path: 'login',
                component: () => import('../pages/auth/Login.vue'),
            },
            {
                name: 'signup',
                path: 'signup',
                component: () => import('../pages/auth/Signup.vue'),
            },
            {
                name: 'recover-password',
                path: 'recover-password',
                component: () => import('../pages/auth/RecoverPassword.vue'),
            },
            {
                name: 'recover-password-email',
                path: 'recover-password-email',
                component: () => import('../pages/auth/CheckTheEmail.vue'),
            },
            {
                path: '',
                redirect: { name: 'login' },
            },
        ],
    },
    {
        name: 'not-found',
        path: '/resource-not-found',
        component: () => import('../pages/404.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        // For some reason using documentation example doesn't scroll on page navigation.
        if (to.hash) {
            return { el: to.hash, behavior: 'smooth' }
        } else {
            window.scrollTo(0, 0)
        }
    },
    routes,
})

async function handleRequireAuth(to: RouteLocationNormalized, next: NavigationGuardNext) {
    const userStore = useUserStore()
    const hasToken = getToken()

    if (hasToken && userStore.uuid.length > 0) {
        next()
    } else {
        try {
            const http = await getCurrentUser()

            userStore.setCurrentUser(http.data)

            next()
        } catch (error) {
            removeToken()
            window.location.href = '/auth/login'
        }
    }
}

async function handleNotRequireAuth(to: RouteLocationNormalized, next: NavigationGuardNext) {
    const userStore = useUserStore()
    const authPages = ['/auth/login', '/auth/signup', '/auth/recover-password']

    const hasToken = getToken()

    if (hasToken && authPages.includes(to.path)) {
        try {
            const http = await getCurrentUser()

            userStore.setCurrentUser(http.data)

            next({ name: 'expense' })
        } catch (error) {
            removeToken()
            window.location.href = '/auth/login'
        }
    } else {
        next()
    }
}

router.beforeEach(async (to, from, next) => {
    if (to.matched.length === 0) {
        next({ name: 'not-found' })
    } else {
        if (to.meta?.requiresAuth) {
            await handleRequireAuth(to, next)
        } else {
            await handleNotRequireAuth(to, next)
        }
    }
})

export default router
