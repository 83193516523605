export interface INavigationRoute {
    name: string
    displayName: string
    meta: { icon: string }
    children?: INavigationRoute[]
}

export default {
    // root: {
    //     name: '/',
    //     displayName: 'navigationRoutes.home',
    // },
    routes: [
        {
            name: 'expense',
            displayName: 'menu.expense',
            meta: {
                icon: 'receipt_long',
            },
        },
        {
            name: 'item',
            displayName: 'menu.item',
            meta: {
                icon: 'inventory_2',
            },
        },
    ] as INavigationRoute[],
}
