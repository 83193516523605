import http from '../services/axios'

type SignUpData = {
    username: string
    email: string
    password: string
}

type LoginCredentials = {
    email: string
    password: string
    keepLoggedIn: boolean | null | undefined
}

type GoogleAuthCredential = {
    credential: string
    client_id: string
}

export function csrfCookie() {
    return http.get('/sanctum/csrf-cookie')
}

export function register(data: SignUpData) {
    return http.post('/register', data)
}

export function login(data: LoginCredentials) {
    return http.post('/login', data)
}

export function logout() {
    return http.post('/logout')
}

export function resolveUserIp() {
    return http.get('https://ipinfo.io/?token=7afa17ebc35a9d')
}

export function getCurrentUser() {
    return http.get('/api/auth/me')
}

export function signinWithGoogle(data: GoogleAuthCredential) {
    return http.post('/google-auth', data)
}
