{
    "auth": {
        "agree": "Eu aceito.",
        "createAccount": "Criar conta",
        "createNewAccount": "Criar uma nova conta",
        "email": "Email",
        "login": "Entrar",
        "password": "Senha",
        "recover_password": "Recuperar senha",
        "sign_up": "Cadastrar-se",
        "keep_logged_in": "Mantenha-me conectado",
        "termsOfUse": "Termos de uso.",
        "reset_password": "Redefinir senha"
    },
    "404": {
        "title": "Esta página está pescando.",
        "text": "Se você acha que isso não está certo, envie-nos uma mensagem em ",
        "back_button": "Voltar para o painel"
    },
    "typography": {
        "primary": "Estilos de texto primários",
        "secondary": "Estilos de texto secundários"
    },
    "dashboard": {
        "versions": "Versões",
        "setupRemoteConnections": "Configurar Conexões Remotas",
        "currentVisitors": "Visitantes Atuais",
        "charts": {
            "trendyTrends": "Tendências na moda",
            "showInMoreDetail": "Mostrar em mais detalhes",
            "showInLessDetail": "Mostrar em menos detalhes",
            "loadingSpeed": "Velocidade de carregamento",
            "topContributors": "Principais colaboradores",
            "showNextFive": "Mostrar próximos cinco",
            "commits": "Commits"
        },
        "info": {
            "componentRichTheme": "tema rico em componentes",
            "completedPullRequests": "solicitações de pull concluídas",
            "users": "usuários",
            "points": "pontos",
            "units": "unidades",
            "exploreGallery": "Explorar galeria",
            "viewLibrary": "Ver Biblioteca",
            "commits": "commits",
            "components": "componentes",
            "teamMembers": "membros da equipe"
        },
        "tabs": {
            "overview": {
                "title": "Visão Geral",
                "built": "Construído com o framework Vue.js",
                "free": "Totalmente gratuito para todos",
                "fresh": "Design fresco e nítido",
                "mobile": "Responsivo e otimizado para dispositivos móveis",
                "components": "Toneladas de componentes úteis",
                "nojQuery": "Completamente livre de jQuery"
            },
            "billingAddress": {
                "title": "Endereço de Cobrança",
                "personalInfo": "Informação Pessoal",
                "firstName": "Nome e Sobrenome",
                "email": "Email",
                "address": "Endereço",
                "companyInfo": "Informação da Empresa",
                "city": "Cidade",
                "country": "País",
                "infiniteConnections": "Conexões infinitas",
                "addConnection": "Adicionar Conexão"
            },
            "bankDetails": {
                "title": "Detalhes Bancários",
                "detailsFields": "Campos de Detalhes",
                "bankName": "Nome do Banco",
                "accountName": "Nome da Conta",
                "sortCode": "Código de Ordenação",
                "accountNumber": "Número da Conta",
                "notes": "Notas",
                "sendDetails": "Enviar Detalhes"
            }
        },
        "navigationLayout": "layout de navegação",
        "topBarButton": "Barra Superior",
        "sideBarButton": "Barra Lateral"
    },
    "language": {
        "brazilian_portuguese": "Português",
        "english": "Inglês",
        "spanish": "Espanhol",
        "simplified_chinese": "Chinês Simplificado",
        "persian": "Persa"
    },
    "menu": {
        "auth": "Autenticação",
        "buttons": "Botões",
        "timelines": "Linhas do Tempo",
        "dashboard": "Painel de Controle",
        "billing": "Cobrança",
        "login": "Entrar",
        "signUp": "Cadastrar-se",
        "preferences": "Preferências",
        "payments": "Pagamentos",
        "pricing-plans": "Planos de Preços",
        "login-singup": "Entrar/Cadastrar",
        "404": "Páginas 404",
        "faq": "FAQ"
    },
    "messages": {
        "all": "Ver todas as mensagens",
        "new": "Novas mensagens de {name}",
        "mark_as_read": "Marcar como Lido"
    },
    "navbar": {
        "messageUs": "Perguntas de desenvolvimento web:",
        "repository": "Repositório GitHub"
    },
    "notifications": {
        "all": "Ver todas as notificações",
        "mark_as_read": "Marcar como lida",
        "sentMessage": "enviou uma mensagem",
        "uploadedZip": "fez upload de um novo arquivo Zip com {type}",
        "startedTopic": "iniciou um novo tópico"
    },
    "user": {
        "language": "Mudar Idioma",
        "logout": "Sair",
        "profile": "Meu Perfil",
        "settings": "Configurações",
        "billing": "Faturamento",
        "faq": "FAQ",
        "helpAndSupport": "Ajuda & Suporte",
        "projects": "Projetos",
        "account": "Conta",
        "explore": "Explorar"
    },
    "treeView": {
        "basic": "Básico",
        "icons": "Icones",
        "selectable": "Selecionável",
        "editable": "Editável",
        "advanced": "Advançado"
    },
    "chat": {
        "title": "Gráficos"
    },
    "helpAndSupport": "Ajuda & Suporte",
    "aboutVuesticAdmin": "Sobre Vuestic Admin",
    "search": {
        "placeholder": "Buscar..."
    }
}
