import Cookies from 'js-cookie'
const TokenKey = 'XSRF-TOKEN'
export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token: string) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    document.cookie = `${TokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.spenda.ng; path=/;`
    Cookies.remove(TokenKey)
}
