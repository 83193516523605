<template>
    <VaLayout class="h-screen bg-[var(--va-background-secondary)]">
        <template #content>
            <div class="p-4">
                <main class="h-full flex flex-row items-center justify-start mx-auto">
                    <div class="flex flex-col items-start">
                        <RouterView />
                    </div>
                </main>
            </div>
        </template>
    </VaLayout>
</template>

<script lang="ts" setup></script>
